import axios, { AxiosError, AxiosResponse } from 'axios';
import jwtDecode from 'jwt-decode';
import qs from 'qs';

import { globalConfig } from '../../configuration/config';

export interface User {
  id?: string;
  userName?: string;
  fullName?: string;
  email?: string;
  language?: string;
  isEnabled?: boolean;
  isLockedOut?: boolean;
  roles?: string[];
  permissions?: string[];
  clientId?: string;
  clientCode?: string;
  jobTitle?: string;
  phoneNumber?: string;
  clientName?: string;
  createdByPlatform?: string;
}

interface Authorization {
  refresh_token: string;
  expires_in: number | string;
  access_token: string;
  token_type?: string;
}

interface CustomErrorResponse {
  data?: {
    error?: string;
    message?: string;
    [key: string]: any;
  };
  status?: number;
}

const getDataErrorMessage = (data: any): string => {
  if (!data) return 'Unknown error';

  if (Array.isArray(data)) {
    return data.join(' ');
  }

  if (typeof data === 'string') {
    return data;
  }

  if (data.error) {
    return data.error;
  }

  if (data.message) {
    return data.message;
  }

  return 'Unknown error';
};

const handleErrorResponse = (error: AxiosError): Promise<never> => {
  const response = error.response as CustomErrorResponse | undefined;
  const status = response?.status;
  const data = response?.data;

  // Handle no response case (network errors, etc.)
  if (!response) {
    return Promise.reject('Network error');
  }

  // Special case: Redirect on forbidden
  if (status === 403) {
    window.location.href = '/access-denied';
    return Promise.reject('Access Forbidden');
  }

  // Handle different status codes
  switch (status) {
    case 400: {
      if (!data) return Promise.reject('Bad Request');

      // Handle invalid grant case
      if (data.error === 'invalid_grant') {
        return Promise.reject('Invalid credentials');
      }

      // Handle array of errors
      if (Array.isArray(data[''])) {
        return Promise.reject(data[''].join(' '));
      }

      // Handle general error message
      return Promise.reject(getDataErrorMessage(data));
    }

    case 401:
      return Promise.reject('Invalid credentials');

    case 404:
      return Promise.reject(data || 'Resource not found');

    case 405:
      return Promise.reject(data || 'Method not allowed');

    default:
      return Promise.reject(getDataErrorMessage(data) || 'An unexpected error occurred');
  }
};
axios.interceptors.response.use(
  (response: AxiosResponse) => response,
  (error: AxiosError) => {
    try {
      return handleErrorResponse(error);
    } catch (e) {
      // Fallback error handling
      console.error('Error in interceptor:', e);
      return Promise.reject('An unexpected error occurred');
    }
  }
);

axios.interceptors.request.use(config => {
  const token = getAuthorizationFromCookie()
    ? 'Bearer ' + getAuthorizationFromCookie().access_token
    : '';
  config.headers.Authorization = token;

  return config;
});

const AUTH_USER_SESSION_KEY = 'current_user';
const AUTH_ACCESS_TOKEN_SESSION_KEY = 'access_token';
const AUTH_REFRESH_SESSION_KEY = 'refresh_token';
const AUTH_EXPIRES_IN_SESSION_KEY = 'expires_in';

/**
 * Sets the default authorization
 * @param {*} token
 */
const setAuthorization = (token: string | null) => {
  if (token) axios.defaults.headers.common['Authorization'] = 'Bearer ' + token;
  else delete axios.defaults.headers.common['Authorization'];
};

const getUserFromCookie = () => {
  const user = sessionStorage.getItem(AUTH_USER_SESSION_KEY);
  return user ? (typeof user == 'object' ? user : JSON.parse(user)) : null;
};

const getAuthorizationFromCookie = (): Authorization => {
  const auth = {} as Authorization;

  auth.access_token = sessionStorage.getItem(AUTH_ACCESS_TOKEN_SESSION_KEY) || '';
  auth.refresh_token = sessionStorage.getItem(AUTH_REFRESH_SESSION_KEY) || '';
  auth.expires_in = sessionStorage.getItem(AUTH_EXPIRES_IN_SESSION_KEY) || '';

  return auth.access_token ? (typeof auth == 'object' ? auth : JSON.parse(auth)) : null;
};

const decodeJWTtoken = ({ access_token, refresh_token, token_type, expires_in }: Authorization) => {
  const decoded: any = jwtDecode<User>(access_token);
  const accessTokenExpiry = new Date();
  accessTokenExpiry.setSeconds(accessTokenExpiry.getSeconds() + Number(expires_in));

  const user = {
    id: decoded.sub,
    username: decoded.name,
    fullname: decoded.fullname,
    email: decoded.email,
    language: decoded.language,
    isEnabled: true,
    roles: Array.isArray(decoded.role) ? decoded.role : [decoded.role],
    permissions: Array.isArray(decoded.permission) ? decoded.permission : [decoded.permission],
    clientId: decoded.clientId,
    clientCode: decoded.clientCode,
  } as User;

  const authorization = {
    refresh_token: refresh_token,
    expires_in: accessTokenExpiry.toISOString(),
    access_token: access_token,
    token_type: token_type,
  };

  return { user, authorization };
};

class APICore {
  /**
   * Fetches data from given url
   */
  get = (url: string, params: any) => {
    axios.defaults.baseURL = globalConfig.get().ApplicationUrl;

    let response;
    if (params) {
      var queryString = params
        ? Object.keys(params)
            .map(key => key + '=' + params[key])
            .join('&')
        : '';
      response = axios.get(`${url}?${queryString}`, params);
    } else {
      response = axios.get(`${url}`, params);
    }
    return response;
  };

  getFile = (url: string, params: any) => {
    return axios.get(`${url}`, {
      params,
      paramsSerializer: params => {
        return qs.stringify(params, { arrayFormat: 'repeat' });
      },
      responseType: 'blob',
    });
  };

  getMultiple = (urls: string, params: any) => {
    const reqs = [];
    let queryString = '';
    if (params) {
      queryString = params
        ? Object.keys(params)
            .map(key => key + '=' + params[key])
            .join('&')
        : '';
    }

    for (const url of urls) {
      reqs.push(axios.get(`${url}?${queryString}`));
    }
    return axios.all(reqs);
  };

  /**
   * post given data to url
   */
  create = (url: string, data: any, config?: any) => {
    return axios.post(url, data, config);
  };

  /**
   * Updates patch data
   */
  updatePatch = (url: string, data: any) => {
    return axios.patch(url, data);
  };

  /**
   * Updates data
   */
  update = (url: string, data: any) => {
    return axios.put(url, data);
  };

  /**
   * Deletes data
   */
  delete = (url: string) => {
    return axios.delete(url);
  };

  /**
   * post given data to url with file
   */
  createWithFile = (url: string, data: any) => {
    const formData = new FormData();
    for (const k in data) {
      formData.append(k, data[k]);
    }

    const config = {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    };
    return axios.post(url, formData, config);
  };

  /**
   * post given data to url with file
   */
  updateWithFile = (url: string, data: any) => {
    const formData = new FormData();
    for (const k in data) {
      formData.append(k, data[k]);
    }

    const config = {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    };
    return axios.patch(url, formData, config);
  };

  isUserAuthenticated = () => {
    const auth = this.getAuthorization() as Authorization;
    if (!auth) return false;

    const decoded: any = jwtDecode(auth.access_token);
    const currentTime = Date.now() / 1000;

    if (decoded.exp < currentTime) {
      console.warn('access token expired');
      return false;
    }

    return true;
  };

  setLoggedInUser = (user?: User | null, authorization?: Authorization | null) => {
    if (user && authorization) {
      sessionStorage.setItem(AUTH_USER_SESSION_KEY, JSON.stringify(user));
      sessionStorage.setItem(AUTH_ACCESS_TOKEN_SESSION_KEY, authorization.access_token);
      sessionStorage.setItem(AUTH_REFRESH_SESSION_KEY, authorization.refresh_token);
      sessionStorage.setItem(AUTH_EXPIRES_IN_SESSION_KEY, authorization.expires_in.toString());
    } else {
      sessionStorage.removeItem(AUTH_USER_SESSION_KEY);
      sessionStorage.removeItem(AUTH_ACCESS_TOKEN_SESSION_KEY);
      sessionStorage.removeItem(AUTH_REFRESH_SESSION_KEY);
      sessionStorage.removeItem(AUTH_EXPIRES_IN_SESSION_KEY);
    }
  };
  /**
   * Returns the logged in user
   */
  getLoggedInUser = () => {
    return getUserFromCookie() as User;
  };

  isUserAdministrator = () => {
    if (!this.isUserAuthenticated()) return false;

    return this.getLoggedInUser().roles?.includes('administrator');
  };

  isDemoUser = () => {
    if (!this.isUserAuthenticated()) return false;

    if (
      this.getLoggedInUser().roles?.includes('PortalKeyUser') ||
      this.getLoggedInUser().roles?.includes('ApiUser')
    ) {
      return true;
    }
  };

  /**
   * Returns the logged in user
   */
  getAuthorization = () => {
    return getAuthorizationFromCookie();
  };

  setUserInSession = (modifiedUser: any) => {
    let userInfo = sessionStorage.getItem(AUTH_USER_SESSION_KEY);
    if (userInfo) {
      const { token, user } = JSON.parse(userInfo);
      this.setLoggedInUser({ token, ...user, ...modifiedUser });
    }
  };
}

/*
Check if token available in session
*/
let user = getUserFromCookie();
if (user) {
  const { token } = user;

  if (token) {
    setAuthorization(token);
  }
}

export { APICore, setAuthorization, decodeJWTtoken };
