import axios from 'axios';
import React from 'react';
import { ReactElement } from 'react';
import ReactDOM from 'react-dom';
import './i18n';
import { Provider } from 'react-redux';

import App from './App';
import { defaultConfig, globalConfig, globalConfigUrl } from './configuration/config';
import i18n from './i18n';
import { configureStore } from './redux/store';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';

const app: ReactElement = (
  <Provider store={configureStore({})}>
    <App />
  </Provider>
);

axios
  .get(window.location.origin + globalConfigUrl)
  .then(response => {
    globalConfig.set(response.data);

    if (i18n.language !== globalConfig.get().DefaultLanguage) {
      i18n.changeLanguage(globalConfig.get().DefaultLanguage || 'en');
    }

    return app;
  })
  .catch(e => {
    if (process.env.NODE_ENV === 'development') {
      globalConfig.set(defaultConfig);
      return app;
    } else {
      const errorMessage =
        'Error while fetching global config, the App wil not be rendered. (This is NOT a React error.)';
      return <p style={{ color: 'red', textAlign: 'center' }}>{errorMessage}</p>;
    }
  })
  .then((reactElement: ReactElement) => {
    ReactDOM.render(reactElement, document.getElementById('root'));

    // If you want your app to work offline and load faster, you can change
    // unregister() to register() below. Note this comes with some pitfalls.
    // Learn more about service workers: https://cra.link/PWA

    // If you want to start measuring performance in your app, pass a function
    // to log results (for example: reportWebVitals(console.log))
    // or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
    serviceWorkerRegistration.register();
  });
