import React from 'react';
import { Route, RouteProps } from 'react-router-dom';

import PartialPrivateRoute from './PartialPrivateRoute';
import PrivateRoute from './PrivateRoute';
import Root from './Root';

// auth
const Login = React.lazy(() => import('../pages/auth/Login'));
const Logout = React.lazy(() => import('../pages/auth/Logout'));
const Confirm = React.lazy(() => import('../pages/auth/Confirm'));
const ForgetPassword = React.lazy(() => import('../pages/auth/ForgetPassword'));
const Register = React.lazy(() => import('../pages/auth/Register'));
const ResetPassword = React.lazy(() => import('../pages/auth/ResetPassword'));
const ConfirmUser = React.lazy(() => import('../pages/auth/ConfirmUser'));

// extra pages
const Error404 = React.lazy(() => import('../pages/error/Error404'));
const Error500 = React.lazy(() => import('../pages/error/Error500'));

// locations map
const MapDashboard = React.lazy(() => import('../pages/dashboard/Map/MapDashboard'));

// clients
const ClientsDashboard = React.lazy(() => import('../pages/dashboard/Clients/ClientsDashboard'));
const ClientNotFound = React.lazy(() => import('../pages/dashboard/Clients/ClientNotFound'));

//location graphs
const LocationGraphs = React.lazy(() => import('../pages/dashboard/Location/LocationGraphs'));

//staticFiles
const PollutionClasses = React.lazy(() => import('../pages/dashboard/OtherPages/PollutionClasses'));
const ProjectPresentation = React.lazy(
  () => import('../pages/dashboard/OtherPages/ProjectPresentation')
);
const UsefulLinks = React.lazy(() => import('../pages/dashboard/OtherPages/UsefulLinks'));
const Reports = React.lazy(() => import('../pages/dashboard/OtherPages/Reports'));

//myAccount
const MyAccount = React.lazy(() => import('../pages/dashboard/Settings/MyAccount'));
const PortalUsers = React.lazy(() => import('../pages/dashboard/Settings/PortalUsers'));

export interface RoutesProps {
  path: RouteProps['path'];
  name?: string;
  component?: RouteProps['component'];
  route?: any;
  exact?: RouteProps['exact'];
  icon?: string;
  header?: string;
  roles?: string[];
  children?: RoutesProps[];
}

// root routes
const rootRoute: RoutesProps = {
  path: '/',
  exact: true,
  component: () => <Root />,
  route: Route,
};

// auth
const authRoutes: RoutesProps[] = [
  {
    path: '/auth/login',
    name: 'Login',
    component: Login,
    route: Route,
  },
  {
    path: '/auth/register',
    name: 'Register',
    component: Register,
    route: Route,
  },
  {
    path: '/auth/confirm',
    name: 'Confirm',
    component: Confirm,
    route: Route,
  },
  {
    path: '/auth/forget-password',
    name: 'Forget Password',
    component: ForgetPassword,
    route: Route,
  },
  {
    path: '/auth/resetPassToken',
    name: 'Login',
    component: ResetPassword,
    exact: true,
    route: Route,
  },
  {
    path: '/auth/logout',
    name: 'Logout',
    component: Logout,
    route: Route,
  },
  {
    path: '/auth/confirmUser',
    name: 'Confirm User',
    component: ConfirmUser,
    route: Route,
  },
];

// public routes
const otherPublicRoutes: RoutesProps[] = [
  {
    path: '/error-404',
    name: 'Error - 404',
    component: Error404,
    route: Route,
  },
  {
    path: '/error-500',
    name: 'Error - 500',
    component: Error500,
    route: Route,
  },
  {
    path: '/clientNotFound',
    name: 'Client not found',
    component: ClientNotFound,
    route: Route,
  },
];

// Locations Map
const mainDashboardRoutes: RoutesProps[] = [
  {
    path: '/clients',
    name: 'Clients',
    icon: 'user',
    exact: true,
    component: ClientsDashboard,
    route: Route,
  },
  {
    path: '/:clientCode/locations/map',
    name: 'Map',
    icon: 'map',
    exact: true,
    component: MapDashboard,
    route: PartialPrivateRoute,
  },
  {
    path: '/:clientCode',
    name: 'Map',
    icon: 'map',
    exact: true,
    component: MapDashboard,
    route: PartialPrivateRoute,
  },
  {
    path: '/:clientCode/pollution/classes',
    name: 'Pollution classes',
    icon: 'bar-chart-2',
    component: PollutionClasses,
    route: PartialPrivateRoute,
  },
  {
    path: '/:clientCode/project/presentation',
    name: 'Project presentation',
    icon: 'info',
    component: ProjectPresentation,
    route: PartialPrivateRoute,
  },
  {
    path: '/:clientCode/useful/links',
    name: 'Useful links',
    icon: 'link',
    component: UsefulLinks,
    route: PartialPrivateRoute,
  },
  {
    path: '/:clientCode/location/:locationId',
    name: 'Location data',
    icon: 'map',
    component: LocationGraphs,
    route: PartialPrivateRoute,
  },
  {
    path: '/:clientCode/reports',
    name: 'Reports',
    icon: 'map',
    component: Reports,
    route: PartialPrivateRoute,
  },
];

// Settings Routes
const settingsRoutes: RoutesProps[] = [
  {
    path: '/myAccount',
    name: 'My Account',
    icon: 'user',
    component: MyAccount,
    route: PrivateRoute,
  },
  {
    path: '/portalUsers',
    name: 'Users',
    icon: 'users',
    component: PortalUsers,
    roles: ['administrator', 'PortalKeyUser'],
    route: PrivateRoute,
  },
];

// flatten the list of all nested routes
const flattenRoutes = (routes: RoutesProps[]) => {
  let flatRoutes: RoutesProps[] = [];

  routes = routes || [];
  routes.forEach((item: RoutesProps) => {
    flatRoutes.push(item);

    if (typeof item.children !== 'undefined') {
      flatRoutes = [...flatRoutes, ...flattenRoutes(item.children)];
    }
  });
  return flatRoutes;
};

// All routes
const authPartialProtectedRoutes = [rootRoute, ...mainDashboardRoutes];
const authProtectedRoutes = [...settingsRoutes];
const publicRoutes = [...authRoutes, ...otherPublicRoutes];

const authPartialProtectedFlattenRoutes = flattenRoutes([...authPartialProtectedRoutes]);
const authProtectedFlattenRoutes = flattenRoutes([...authProtectedRoutes]);
const publicProtectedFlattenRoutes = flattenRoutes([...publicRoutes]);
export {
  publicRoutes,
  authPartialProtectedRoutes,
  authPartialProtectedFlattenRoutes,
  authProtectedFlattenRoutes,
  authProtectedRoutes,
  publicProtectedFlattenRoutes,
};
