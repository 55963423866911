interface MapConfig {
  mapBoxApiToken: string;
  minZoom: number;
  maxZoom: number;
  zoom: number;
  type: string;
}

export interface DynamicConfig {
  ApplicationUrl: string;
  Clients: string;
  Map: MapConfig;
  DefaultLanguage?: string;
}

export const defaultConfig: DynamicConfig = {
  ApplicationUrl: 'https://localhost:44309',
  Clients: '',
  Map: {
    mapBoxApiToken:
      'pk.eyJ1Ijoic2Vuc3dhcmUiLCJhIjoiY2w4NzB6dmUwMHp2aDNucXR6cTBpbGY1dyJ9.Vv7u1uYKPxv3xueWmmdGUQ',
    zoom: 18,
    minZoom: 10,
    maxZoom: 18,
    type: 'mapbox/outdoors-v12',
  },
  DefaultLanguage: 'en', // default language en, sl, de
};

class GlobalConfig {
  config: DynamicConfig = defaultConfig;

  public get(): DynamicConfig {
    return this.config;
  }

  public set(value: DynamicConfig): void {
    this.config = value;
  }
}

export const globalConfig = new GlobalConfig();

export const globalConfigUrl = '/config/config.json';
