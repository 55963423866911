//Password must be at least one uppercase, one lowercase, one special character and one number.
const generateRandomPassword = (passwordLength: number) => {
  const uppercase = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ';
  const lowercase = 'abcdefghijklmnopqrstuvwxyz';
  const numbers = '0123456789';
  const special = '!@?';
  const all = uppercase + lowercase + numbers + special;
  let password = '';
  password += uppercase.charAt(Math.floor(Math.random() * uppercase.length));
  password += lowercase.charAt(Math.floor(Math.random() * lowercase.length));
  password += numbers.charAt(Math.floor(Math.random() * numbers.length));
  password += special.charAt(Math.floor(Math.random() * special.length));

  for (let i = 0; i < passwordLength - 4; i++) {
    password += all.charAt(Math.floor(Math.random() * all.length));
  }

  return password
    .split('')
    .sort(() => Math.random() - 0.5)
    .join('');
};

export { generateRandomPassword };
