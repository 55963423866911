import { sl, de, enGB } from 'date-fns/locale';

function localizationMapper(languageCode: string) {
  switch (languageCode) {
    case 'sl':
      return 'sl-SI';
    case 'de':
      return 'de-DE';
    case 'en':
      return 'en-GB';
    default:
      return 'en-GB';
  }
}

function localizationDateMapper(languageCode: string) {
  switch (languageCode) {
    case 'sl':
      return sl;
    case 'de':
      return de;
    case 'en':
      return enGB;
    default:
      return enGB;
  }
}

export { localizationMapper, localizationDateMapper };
