import React, { useContext, useMemo } from 'react';
import { Route, Redirect, RouteProps, RouteComponentProps, matchPath } from 'react-router-dom';

import { APICore } from '../helpers/api/apiCore';
import ClientsContext from '../pages/dashboard/Location/Context/ClientsContext';

interface PartialPrivateRouteProps {
  component: React.FunctionComponent<RouteProps>;
  roles?: string[];
}

export interface RouteParams {
  clientCode?: string;
  locationId?: string;
}

/**
 * PArtial Private Route redirects user to login if he wqas logged in but session is expired
 * @param {*} param0
 * @returns
 */
const PartialPrivateRoute = ({
  component: Component,
  roles,
  ...rest
}: PartialPrivateRouteProps) => {
  const api = useMemo(() => new APICore(), []);
  const loggedInUser = api.getLoggedInUser();
  const { clients } = useContext(ClientsContext);

  return (
    <Route
      {...rest}
      render={(props: RouteComponentProps) => {
        const match = matchPath<{ clientCode: string }>(props.location.pathname, {
          path: props.match.path,
          exact: false,
          strict: false,
        });

        const client = clients.find(c => c.code === match?.params.clientCode);

        if (!loggedInUser && !api.isUserAuthenticated() && !client?.isPublic) {
          return (
            <Redirect
              to={{
                pathname: '/auth/login',
                state: { from: props['location'] },
              }}
            />
          );
        }

        // če clientCode ni pravilen ga preusmeri na client doesn't exist
        if (
          (loggedInUser === null && match?.params.clientCode === undefined) ||
          (match?.params.clientCode && !client)
        ) {
          return <Redirect to={{ pathname: `/clientNotFound` }} />;
        }

        // check if route is restricted by role
        if (roles && roles?.some(r => loggedInUser.roles!.indexOf(r) < 0)) {
          // role not authorised so redirect to home page
          return <Redirect to={{ pathname: `/` }} />;
        }

        // authorised so return component
        return <Component {...props} />;
      }}
    />
  );
};

export default PartialPrivateRoute;
