import { createContext } from 'react';

import { Location } from '../../../../helpers/api/apiPortalData';

interface ILocationsContext {
  locations: Location[];
  selectedLocation: Location;
  setLocations: (locations: Location[] | any[], resetLocations?: boolean) => void;
  setSelectedLocation: (location: Location) => void;
}

const LocationsContext = createContext<ILocationsContext>({
  locations: [] as Location[],
  selectedLocation: {} as Location,
  setLocations: (locations: Location[]) => {},
  setSelectedLocation: (location: Location) => {},
});

export default LocationsContext;
