import i18n from 'i18next';
import detector from 'i18next-browser-languagedetector';
import { initReactI18next } from 'react-i18next';

import { globalConfig } from './configuration/config';
import translationDe from './locales/de/translation.json';
import translationSl from './locales/sl/translation.json';

//translations
const resources = {
  sl: {
    translation: translationSl,
  },
  de: {
    translation: translationDe,
  },
};

i18n
  .use(detector)
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    resources,
    fallbackLng: globalConfig.get().DefaultLanguage || 'en',
    lng: globalConfig.get().DefaultLanguage || 'en',

    keySeparator: false, // we do not use keys in form messages.welcome

    interpolation: {
      escapeValue: false, // react already safes from xss
    },
  });

export default i18n;
